.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 6%;
    transition: all 0.3s ease;
}

.colored {
    background-color: #0713F3;
    transition: all 0.3s ease;
}

.logo {
    height: 40px;
}

.burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
}

.line, .lineOpen {
    width: 100%;
    height: 3px;
    background-color: #ffffff;
    transition: all 0.3s ease;
}

.lineOpen:nth-child(1) {
    transform: rotate(45deg) translate(6px, 6px);
}

.lineOpen:nth-child(2) {
    opacity: 0;
}

.lineOpen:nth-child(3) {
    transform: rotate(-45deg) translate(6px, -6px);
}

.menu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0713F3;
    padding: 20px 0;
    z-index: 1;
}

.navBar {
    list-style: none;
    padding: 0;
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.navItem {
    font-size: 36px;
    font-weight: 600;
    text-transform: uppercase;
}

.navLink {
    color: #ffffff;
    text-decoration: none;
}
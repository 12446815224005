.container {
    position: relative;
}

.wrapper {
    padding: 150px 6%;
}

.wrapperMob {
    padding: 30px 6%;
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.title {
    font-family: 'Red Rose', sans-serif;
    font-size: 64px;
    line-height: 76px;
    color: #ffffff;
    text-transform: uppercase;
}

.titleMob {
    font-size: 40px;
    line-height: 47px;
}

.text {
    margin: 20px 0 50px;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
}

.btnsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.btnsWrapperMob {
    flex-direction: column;
}

.btnIron {
    padding: 16px 46px;
    border-radius: 100px;
    background: linear-gradient(90deg, #BFBFBF 0%, #FFFFFF 46%, #BFBFBF 100%);
    color: #0713F3;
    cursor: pointer;
}

.btnTransparent {
    padding: 16px 32px;
    border: 2px solid #ffffff;
    border-radius: 100px;
    color: #ffffff;
    cursor: pointer;
}

.btnMob {
    width: 100%;
}
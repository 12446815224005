.container {
    background-image: url("../../assets/img/banner_bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.wrapper {
    padding: 205px 6%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 50px;
}

.wrapperMob {
    padding: 336px 6% 154px!important;
}

.wrapperMobS {
    padding: 264px 6% 70px!important;
}

.title {
    max-width: 736px;
    font-family: 'Red Rose', sans-serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 70px;
    color: #ffffff;
    text-transform: uppercase;
    text-align: left;
}

.titleMob {
    font-size: 32px;
    line-height: 37px;
}

.titleSpan {
    color: #BFBFBF;
}

.contactBtns {
    display: flex;
    align-items: center;
    gap: 20px;
}

.contactBtnsMob {
    flex-direction: column;
}

.btnIron {
    padding: 16px 46px;
    border-radius: 100px;
    background: linear-gradient(90deg, #BFBFBF 0%, #FFFFFF 46%, #BFBFBF 100%);
    color: #0713F3;
    cursor: pointer;
}

.btnTransparent {
    padding: 16px 32px;
    border: 2px solid #ffffff;
    border-radius: 100px;
    color: #ffffff;
    cursor: pointer;
}
.container {
    height: 548px;
    border-radius: 16px;
    background-color: #ffffff;
}

.poster {
    border-radius: 16px;
}

.content {
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.blogInfo {
    display: flex;
    align-items: center;
    gap: 12px;
}

.topic {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 8px;
    background-color: #0713F3;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #FAFAFA;
}

.time {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #3D3D3D;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
}

.blogTitle {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    color: #2E2E2E;
}

.blogText {
    font-size: 14px;
    line-height: 20px;
    color: #2E2E2E;
}
.container {
    min-height: 100vh;
    position: relative;
}

.wrapper {
    padding: 150px 6% 100px;
}

.wrapperMob {
    padding: 30px 6%;
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.title {
    margin-bottom: 70px;
    font-family: 'Red Rose', sans-serif;
    font-size: 60px;
    line-height: 72px;
    color: #ffffff;
}

.titleMob {
    margin-bottom: 30px;
    font-size: 40px;
}

.testimonialWrapper {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navBtnsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 40px;
}

.customprevious {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    cursor: pointer;
}

.customnext {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    cursor: pointer;
    transform: rotate(180deg);
}

.customprevious:hover,
.customnext:hover {
    border: none;
    background-color: #ffffff;
}

.customprevious:hover img,
.customnext:hover img {
    filter: invert(34%) sepia(100%) saturate(747%) hue-rotate(190deg) brightness(92%) contrast(92%);
}

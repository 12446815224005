.container {
    padding: 30px 6%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logoWrapper {}
.logoImg {}

.navBar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
}

.navItem {}
.navLink {}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 18px 28px;
    border: 2px solid #ffffff;
    border-radius: 100px;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
}

.btnImg {}
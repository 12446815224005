.container {
    min-height: 100vh;
    position: relative;
}

.wrapper {
    padding: 180px 6%;
}

.wrapperMob {
    padding: 30px 6%;
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.title {
    font-family: 'Red Rose', sans-serif;
    font-size: 60px;
    line-height: 72px;
    color: #000000;
    text-align: left;
}

.titleMob {
    text-align: center;
    font-size: 40px;
}

.blogList {
    margin-top: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blogListMob {
    margin-top: 30px;
    flex-direction: column;
    gap: 40px;
}
.container {
    background-color: #141414;
}

.wrapper {
    padding: 160px 6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.socialBlock {
    display: flex;
    flex-direction: column;
    gap: 45px;
    position: relative;
}

.logo {
    max-width: 214px;
}

.navBlock {
    padding: 55px 25px;
    border-left: 1px solid #0713F3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 258px;
    gap: 24px;
}

.link {
    display: flex;
    align-items: center;
    gap: 25px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
    text-align: left;
    text-transform: uppercase;
}
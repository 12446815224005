.container {
    height: 450px;
    background-color: #3F46CD;
    border-radius: 24px;
    padding: 63px 24px 37px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
}

.mainContent {
    display: flex;
    flex-direction: column;
}

.photo {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.text {
    color: #ffffff;
}

.authorWrapper {
    display: flex;
    flex-direction: column;
}

.company {
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
}

.author {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #ffffff;
}
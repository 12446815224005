.container {
    background-image: url("../../assets/img/about_bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.wrapper {
    padding: 180px 6% 206px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
}

.wrapeerMob {
    padding: 60px 6%;
    align-items: flex-start;
}

.text {
    line-height: 30px;
    width: 45%;
    text-align: left;
}

.textMob {
    width: 100%;
}

.textDark {
    color: #0D0D0D;
}

.btn {
    margin-top: 10px;
    margin-right: 34%;
    padding: 18px 48px;
    background-color: #0713F3;
    border-radius: 100px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.btnMob {
    width: 100%;
    margin: 70px 0 0;
}

.sign {
    position: absolute;
    left: 6%;
    bottom: 206px;
}

.signMob {
    position: absolute;
    left: 6%;
    bottom: 140px;
}

.signTitle {
    font-size: 16px;
    text-align: left;
}

.name {
    font-family: 'Red Rose', sans-serif;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
    text-align: left;
}
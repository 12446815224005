.container {
    background-image: url("../../assets/img/roles_bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.wrapper {
    padding: 0 6% 90px;
}

.title {
    font-family: 'Red Rose', sans-serif;
    padding: 85px 0;
    text-align: left;
    font-size: 60px;
    line-height: 80px;
    color: #0D0D0D;
}

.titleMob {
    padding: 30px 0;
    font-size: 40px;
}

.mainContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.mainContentMob {
    flex-direction: column;
}

.col {
    margin-right: 6%;
    width: 37%;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 136px;
}

.colMob {
    gap: 80px;
    margin: 0;
    width: 100%;
}

.shifted {
    margin-top: 200px;
}

.shiftedMob {
    margin-top: 80px;
}

.item {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.icon {
    width: 60px;
}

.text {
    color: #0D0D0D;
}

p {
    line-height: 29px;
}
.container {
    background-color: #141414;
    padding: 60px 6%;
}

.socialBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
}


.logo {
    max-width: 50%;
}

.navBlock {
    padding: 30px 30px;
    border-left: 1px solid #0713F3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    column-gap: 40px;
    row-gap: 20px;
}

.link {
    display: flex;
    align-items: center;
    gap: 25px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
    text-align: left;
    text-transform: uppercase;
}